<template>
    <div style="padding-bottom: 50px;">
        <van-notice-bar mode="closeable" wrapable :scrollable="false" v-if="finished && !orders.length">
            您尚未接受过探店任务，请在“我的”认证后在“去探店”接单
        </van-notice-bar>

        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadData" offset="100">

                <van-card v-for="(item,index) in orders" :key="index" style="background: #fff;" @click="workDetail(item)" :thumb="item.head_img">
                    <div slot="title">
                        <span style="font-size: 14px;font-weight: bold;margin-right: 5px;">{{item.shop_name}}</span>
                        <van-tag type="danger" v-if="item.offline" style="margin-right: 5px;">需到店</van-tag>
                        <van-tag v-if="item.status === 0" plain type="danger" style="margin-right: 5px;">{{item.status_name}}</van-tag>
                        <van-tag v-if="item.status === 1" plain style="margin-right: 5px;">{{item.status_name}}</van-tag>
                        <van-tag v-if="item.status === 2" plain style="margin-right: 5px;">{{item.status_name}}</van-tag>
                        <van-tag v-if="item.status === 3" plain style="margin-right: 5px;">{{item.status_name}}</van-tag>
                        <van-tag v-if="item.status === 4" plain type="success" style="margin-right: 5px;">{{item.status_name}}</van-tag>
                        <van-tag v-if="item.status === 5" plain type="danger" style="margin-right: 5px;">{{item.status_name}}</van-tag>
                        <van-tag v-if="item.status === 6" plain style="margin-right: 5px;">{{item.status_name}}</van-tag>
                        <span style="float: right;color: #aaa;margin-top: 2px;" v-if="!item.detail_time">查看详情&nbsp;></span>
                    </div>
                    <div slot="desc" class="card_desc">
                        <p v-if="item.address">店铺区域：{{item.short_name}}</p>
                        <p v-if="item.address">店铺地址：{{item.address}}</p>
                        <p v-if="item.platform">店铺平台：{{item.platform}}</p>
                        <p>探店时间：{{item.start_date}}{{item.start_date === item.end_date?'':'~'+item.end_date}}</p>
                        <p v-if="item.status === 5 && item.accept_remark">拒回原因：<span style="color: red;">{{item.accept_remark}}</span></p>
                        <p v-if="(item.status === 0 || item.status === 5 )&& item.cancel_at">
                            <van-count-down :time="item.cancel_at * 1000" @finish="onRefresh({overtime:true})" style="font-size: 12px;" >
                                <template v-slot="timeData">
                                    剩余投稿时间：<span v-if="timeData.days > 0">{{timeData.days}}天&nbsp;</span>{{timeData.hours>9?timeData.hours:'0'+timeData.hours}}:{{timeData.minutes>9?timeData.minutes:'0'+timeData.minutes}}:{{timeData.seconds>9?timeData.seconds:'0'+timeData.seconds}}
                                </template>
                            </van-count-down>
                        </p>
                    </div>
                    <div slot="price">
                        <span class="van-card__price-integer" style="color:#ee0a24">¥&nbsp;{{item.payment}}</span>
                    </div>
                    <div slot="num">
                        <div v-if="[0,1,2,3,5].includes(item.status)">
                            <van-button v-if="item.status === 0 && !item.detail_time" size="mini" round style="padding: 0 10px;" color="linear-gradient(to right,#ff6034,#ee0a24)" @click.stop="$router.push({ path: '/talent/upload', query: { work_id: item.work_id }})">上传探店凭证</van-button>
                            <van-button v-if="item.status === 0 && item.detail_time" size="mini" round style="padding: 0 10px;">
                                    <van-count-down :time="item.detail_time*1000" style="display: inline-block;" @finish="finish(index)" />
                                    <span style="line-height: 20px;">&nbsp;后可投稿</span>
                            </van-button>
                            <van-button v-if="item.status === 1" size="mini" round style="padding: 0 10px;" @click.stop="notify('投稿完成3天后将自动进入待结算阶段，请耐心等待')">待验收</van-button>
                            <van-button v-if="item.status === 2" size="mini" round style="padding: 0 10px;" @click.stop="notify('投稿完成3天后将自动进入待结算阶段，请耐心等待')">已验收，等待结算</van-button>
                            <van-button v-if="item.status === 3" size="mini" round style="padding: 0 10px;" @click.stop="notify('请等待工作人员审核，审核通过后将自动发送微信红包到您的账号，请注意查收','warning')">验收通过，待结算</van-button>
                            <van-button v-if="item.status === 5 && item.hang_up !== 1" size="mini" round style="padding: 0 10px;" color="linear-gradient(to right,#ff6034,#ee0a24)" @click.stop="$router.push({ path: '/talent/upload', query: { work_id: item.work_id }})">重新上传</van-button>
                            <van-button v-if="item.status === 5 && item.hang_up === 1" size="mini" round style="padding: 0 10px;" @click.stop="notify('管理员正在审核您的申诉诉求，请耐心等待')">申诉中</van-button>
                        </div>
                    </div>
                </van-card>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
    import {Notify,Toast} from 'vant';
    import {mapState} from 'vuex';
    export default {
        name: "ExploreReceivedDialog",
        computed:{
            ...mapState(['explore']),
        },
        data(){
            return{
                pageSize:10,
                pageNo:1,

                orders:[],

                refreshing: false,
                finished: false,
                loading:false,

            }
        },
        methods:{
            loadData (params = {}) {
                let that = this;
                Object.assign(params,{
                    pageSize:this.pageSize,
                    pageNo:this.pageNo,
                })
                this.post('/api/client/talent/history/received',params).then(result=>{
                    if(result.code === 0){
                        // 刷新
                        if (this.refreshing) {
                            this.orders = [];
                            this.refreshing = false;
                        }
                        // 加数据
                        for (let i = 0; i < result.data.length; i++) {
                            this.orders.push(result.data[i]);
                        }
                        // pageNo++
                        that.pageNo = that.pageNo + 1;
                        this.finished = result.data.length < that.pageSize;
                        this.loading = false;
                    } else {
                        Notify(result.msg);
                    }
                })
            },
            onRefresh(params) {
                // 清空列表数据
                this.finished = false;
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.refreshing = true;
                this.loading = true;
                this.pageNo = 1;
                this.loadData(params);
            },
            notify (message,type='primary') {
                Notify({type: type, message: message});
            },
            workDetail(item){
                if(item.detail_time) { // 如果还在倒计时，那么不能查看详情
                    this.notify('接单3小时后才可以查看订单详情内容，请3小时后再点评和投稿噢');
                    return;
                }
                this.$router.push({path: '/explore/detail', query: {work_id: item.work_id}});
            },
            finish(index){
                console.log('finish');
                console.log(index);
                this.orders[index].detail_time = null;
            },
        }
    }
</script>

<style scoped>
    .order_box{
        margin: 10px 10px 15px;
        border: 1px solid #f7f8fa;
        border-radius: 10px;
        background: #fff;
        padding-top: 10px;
        box-shadow: 0 8px 12px #ebedf0;
    }
</style>
<style>
    .card_desc p {
        margin: 3px 0;
    }
</style>