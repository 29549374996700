<template>
    <div style="font-size: 14px;padding-bottom: 60px;height: 100%;" class="history_div">
        <van-switch-cell :value="setting.notice" @input="noticeChange" active-color="#e2ba7f" title="接收新订单通知开关" />
        <van-tabs v-model="common.talentIndex" @click="onClick">
            <van-tab title="可接任务">
                <explore-list-dialog ref="exploreListDialog"></explore-list-dialog>
            </van-tab>
            <van-tab title="已接任务">
                <explore-received-dialog ref="exploreReceivedDialog"></explore-received-dialog>
            </van-tab>
        </van-tabs>
        <talent-tabbar-dialog :active="1" @navigate="navigate"></talent-tabbar-dialog>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import TalentTabbarDialog from "../Common/Dialog/TalentTabbarDialog";
    import ExploreListDialog from "./Dialog/ExploreListDialog";
    import ExploreReceivedDialog from "./Dialog/ExploreReceivedDialog";
    import {Toast} from "vant";

    export default {
        name: "TalentHistory",
        components: {
            TalentTabbarDialog,
            ExploreListDialog,
            ExploreReceivedDialog,
        },
        computed:{
            ...mapState(['common']),
        },
        data(){
            return{
                setting:{
                    notice: false,
                },
            }
        },
        mounted() {
            this.post('/api/client/talent/history').then(result => {
                this.setting.notice = result.data.notice;
            })
            if(this.$route.query.tab === undefined) return;
            console.log(this.$route.query);
            this.common.talentIndex = parseInt(this.$route.query.tab);
        },
        methods: {
            navigate(page) {
                this.$router.replace(page);
            },
            onClick(index) {
                this.common.talentIndex = index;
            },
            noticeChange(notice){
                let that = this;
                console.log(notice);
                Toast.loading({
                    message: '正在修改...',
                    forbidClick: true,
                    duration: 0,
                });
                this.post('/api/client/talent/notice',{notice: notice}).then(result=>{
                    Toast.clear();
                    if(result.code === 0){
                        that.setting.notice = notice;
                    } else {
                        that.setting.notice = !notice;
                        Toast('修改失败，请稍后重试');
                    }
                }).catch(e=>{
                    that.setting.notice = !notice;
                    Toast.clear();
                    Toast('修改失败，请稍后重试');
                });
            },
        }
    }
</script>

<style scoped>

</style>